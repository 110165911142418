export class ValidationMessages {
   public static get productionUrl(): string { return 'insurancerater.com'; }
   public static get productionUrlWithWww(): string { return 'www.insurancerater.com'; }
   public static get productionNormandyDomain(): string { return 'normandy.insurancerater.com'; }
   public static get productionCanopyDomain(): string { return 'canopy.insurancerater.com'; }
   public static get productionPbibinsDomain(): string { return 'pbibins.insurancerater.com'; }
   // public static get productionUnetinsDomain(): string { return 'unetins.insurancerater.com'; }
   // public static get productionPrimeisDomain(): string { return 'primeis.insurancerater.com'; }
   //Production Api
   public static get productionAPUrl(): string { return 'api.insurancerater.com'; }

   // public static get stagingUrl(): string { return 'http://44.200.33.183'; }
   public static get stagingUrl(): string { return 'https://api.shieldrater.com'; }
   public static get stagingNormandyDomain(): string { return 'normandy.shieldrater.com'; }
   public static get stagingCanopyDomain(): string { return 'canopy.shieldrater.com'; }
   public static get stagingPbibinsDomain(): string { return 'pbibins.shieldrater.com'; }
   // public static get stagingUnetinsDomain(): string { return 'unetins.shieldrater.com'; }
   // public static get stagingPrimeisDomain(): string { return 'primeis.shieldrater.com'; }
   
   public static get localNormandyDomain(): string { return 'localhost:4200'; }
   public static get logoVersion(): string { return '223344'; }

   public static get auditCompletedCCEmailProd(): string { return 'ddavis@shieldins.net,rmartin@shieldins.net,kavary@shieldins.net,audits@shieldins.net,ylua@shieldins.net,kgarcia@shieldins.net'; }
   public static get auditCompletedCCEmailStag(): string { return 'test-ddavis@test-shieldins.net,test-rmartin@test-shieldins.net,test-kavary@test-shieldins.net,tst-audits@test-shieldins.net,test-ylua@test-shieldins.net,test-kgarcia@test-shieldins.net'; }

   public static get auditCompletedRPEmailProd(): string { return 'severez@shieldins.net'; }
   public static get auditCompletedRPEmailStag(): string { return 'test-severez@shieldins.net'; }

   public static get auditNonCompletedEmailProd(): string { return 'lgarcia@shieldins.net,kgarcia@shieldins.net,audits@shieldins.net,ylua@shieldins.net'; }
   public static get auditNonCompletedEmailStag(): string { return 'test-lgarcia@test-shieldins.net,test-kgarcia@test-shieldins.net,test-audits@test-shieldins.net,test-ylua@test-shieldins.net'; }

   public static get auditEvenCloseEmailProd(): string { return 'ddavis@shieldins.net,rmartin@shieldins.net,kavary@shieldins.net,audits@shieldins.net,ylua@shieldins.net'; }
   public static get auditEvenCloseEmailEmailStag(): string { return 'test-ddavis@test-shieldins.net,test-rmartin@test-shieldins.net,test-kavary@test-shieldins.net,test-audits@test-shieldins.net,test-ylua@test-shieldins.net'; }

   public static get auditCompletedRPCCEmailProd(): string { return 'ddavis@shieldins.net,rmartin@shieldins.net,kavary@shieldins.net,audits@shieldins.net,ylua@shieldins.net,mreyes-gil@shieldins.net,ggonzalez@shieldins.net,mgawarecki@shieldins.net'; }
   
   public static get auditCompletedRPCCEmailStag(): string { return 'test-ddavis@test-shieldins.net,test-rmartin@test-shieldins.net,test-kavary@test-shieldins.net,test-audits@test-shieldins.net,test-ylua@test-shieldins.net,test-mreyes-gil@test-shieldins.net,test-ggonzalez@test-shieldins.net,test-mgawarecki@test-shieldins.net'; }

   public static get auditCompletedAPEmailProd(): string { return 'ddavis@shieldins.net,rmartin@shieldins.net,kavary@shieldins.net,audits@shieldins.net,ylua@shieldins.net,kgarcia@shieldins.net,mreyes-gil@shieldins.net,lgarcia@shieldins.net,ggonzalez@shieldins.net,mgawarecki@shieldins.net'; }
   
   public static get auditCompletedAPEmailStag(): string { return 'test-ddavis@test-shieldins.net,test-rmartin@test-shieldins.net,test-kavary@test-shieldins.net,test-audits@test-shieldins.net,test-ylua@test-shieldins.net,test-kgarcia@test-shiledins.net,test-mreyes-gil@test-shieldins.net,test-lgarcia@test-shieldins.net,test-ggonzalez@test-shieldins.net,test-mgawarecki@test-shieldins.net'; }

   public static get auditCompletedAPPaidEmailProd(): string { return 'ddavis@shieldins.net,rmartin@shieldins.net,kavary@shieldins.net,audits@shieldins.net,ylua@shieldins.net,kgarcia@shieldins.net,mreyes-gil@shieldins.net,lgarcia@shieldins.net,ggonzalez@shieldins.net,mgawarecki@shieldins.net'; }

   public static get auditCompletedAPPaidEmailStag(): string { return 'test-ddavis@test-shieldins.net,test-rmartin@test-shieldins.net,test-kavary@test-shieldins.net,test-audits@test-shieldins.net,test-ylua@test-shieldins.net,test-kgarcia@test-shiledins.net,test-mreyes-gil@test-shieldins.net,test-lgarcia@test-shieldins.net,test-ggonzalez@test-shieldins.net,test-mgawarecki@test-shieldins.net'; }
   
   public static get shieldMainLoader(): string { return "<img src='assets/images/loading_gif.gif' width='250px' style='margin-top:25%'>"; }
   public static get shieldNormandyLoader(): string { return "<img src='assets/images/normandy_loader.gif' width='60px' height= '60px' style='margin-top:25%; background-color: rgb(300, 300, 300);'>"; }

   public static get stagReqLossRuns(): string { return 'prasad@tabletoptech.in'; }
   public static get prodReqLossRuns(): string { return 'lossruns@shieldins.net'; }
}